import * as Sentry from "@sentry/react"

// These patterns are case-insensitive.
const stringsToIgnore: RegExp[] = [
  /googletagmanager/i,
  /failed to fetch/i,
  /No credential returned/i,
  /Load failed/i,
  /Unexpected token '<'/i,
  /Firebase: Error \(auth\/network-request-failed\)/i,
]

// Ignores are per here:
// https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
// iOS only when someone seems to navigate away from the page when a fetch
// request is in progress, which is fine.
Sentry.init({
  dsn: "https://e83dc5d4b1f743c59a6485ffd0d06367@o401335.ingest.sentry.io/5260776",
  environment:
    process.env.REACT_APP_ENV === "production" ? "production" : "development",
  release: process.env.REACT_APP_SENTRY_RELEASE,
  ignoreErrors: [
    "AbortError: The user aborted a request",
    "Fetch is aborted",
    "TypeError: Failed to fetch",
    "Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: Cancelled",
    "TypeError: cancelled",
    "Uncaught in availability search: cancelled",
    "Non-Error promise rejection captured with value: cancelled",
    "Error: UnknownError",
    "ChunkLoadError",
    "Network Error",
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    "ResizeObserver",
    // These next ones are all archived in Sentry and appear to be non-issues.
    "Load failed",
    "TypeError: Load failed",
    "Error: Failed to load https://wwww.googletagmanager",
    "Error: No credential returned",
    "Non-Error promise rejection captured with value: Timeout",
    "The provided ID token is not a valid Firebase ID token.",
  ],
  beforeSend: (event, hint) => {
    // This seems to be a more robust way to ignore errors.
    const error = hint.originalException as Error
    if (
      error?.message &&
      stringsToIgnore.some((regex) => regex.test(error.message))
    ) {
      // Returning null tells Sentry to ignore the error.
      return null
    }
    return event
  },
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  integrations: [], // replay lazy-loaded below
  replaysSessionSampleRate: 0.1, // recommended in production
  replaysOnErrorSampleRate: 1,
})

// eslint-disable-next-line import/no-extraneous-dependencies
import("@sentry/react").then((lazyLoadedSentry) => {
  Sentry.addIntegration(lazyLoadedSentry.replayIntegration())
})
